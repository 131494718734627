.plans-container {
    padding: 2rem;
    gap: 4rem;
    position: relative;
    margin-top:-4rem;
    display: flex; 
    flex-direction: column;
  }
  
  
  .plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }
  
  .plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard); /* Assuming you have defined this variable */
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
  }
  
  .plan:nth-child(2) {
    background: var(--planCard); /* Assuming you have defined this variable */
    transform: scale(1.1);
  }
  
  .plan > svg {
    fill: var(--orange); /* Assuming you have defined this variable */
    width: 2rem;
    height: 2rem;
  }
  
  .plan > :nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
  }
  
  .plan > :nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .plan > :nth-child(5) {
    font-size: 0.8rem;
  }
  
  .features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .feature {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .feature > img {
    width: 1rem;
  }
  
  .plans > :nth-child(2) > svg {
    fill: white;
  }
  
  .plans > :nth-child(2) > button {
    color: orange;
  }
  .btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  
  
  

  
  
  
  